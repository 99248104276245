import { initializeApp } from "firebase/app";
// import 'firebase/auth'
// import { getFirestore, collection } from "firebase/firestore";
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

// import { computed, onMounted, onUnmounted, ref } from "vue";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB_HWFjE5bacDZmCGgj3TvbV72-Pn26isc",
  authDomain: "prepworx-app.firebaseapp.com",
  projectId: "prepworx-app",
  storageBucket: "prepworx-app.appspot.com",
  messagingSenderId: "949688756169",
  appId: "949688756169:web:64aff38acdc09c2e526a18"
});

// export const useAuthState = () => {
//   const user = ref(null);
//   const error = ref(null);
//   const auth = getAuth();
//   let unsubscribe;
//   onMounted(() => {
//     unsubscribe = onAuthStateChanged(
//       auth,
//       u => (user.value = u),
//       e => (error.value = e)
//     );
//   });
//   onUnmounted(() => unsubscribe());

//   const isAuthenticated = computed(() => user.value != null);
//   return { user, error, isAuthenticated };
// };

// export const useSignOut = async () => {
//   try {
//     const auth = getAuth();
//     await signOut(auth);
//   } catch (e) {
//     alert(e.message);
//   }
// };

// export const getUserState = () =>
//   new Promise((resolve, reject) =>
//     onAuthStateChanged(getAuth(), resolve, reject)
//   );

// export const db = getFirestore(firebaseApp);

// export const auth = getAuth();
<script setup>
  import Loader from "@/components/Loader.vue"
  import useUserStore from '@/stores/user.js'
  import usedbStore from '@/stores/db.js'
  import {
    useCurrentUser
  } from 'vuefire';
  import * as Sentry from "@sentry/vue";
  const userStore = useUserStore()
  const dbStore = usedbStore()
  const user = useCurrentUser()


  if (!dbStore.stateData.initialized && userStore.stateData.isLoggedIn) {
    dbStore.initialize(userStore.stateData.uid)
  }

  const refreshUser = async() => {
    if (userStore.stateData.isLoggedIn) {
      try {
        user.value.getIdTokenResult(true)
          .then(function (idToken) {
            console.log("Successfully refreshed user.")
          }).catch(function (error) {
            console.error(error)
          });
      } catch (error) {
        if (error.message == "user.value is null") {
          window.location = '/logout'
        } else {
          console.error(error)
        }
      }
    }
  }
  setInterval(() => {
    refreshUser()
  }, 1000*60*30)
  setTimeout(() => {
    refreshUser()
  }, 1000*5)
  
  if (userStore.stateData.isLoggedIn) {
    Sentry.setUser({ email: userStore.stateData.email, username: userStore.stateData.uid });
    OpenReplay.setUserID(userStore.stateData.uid)
  }
</script>

<template>
  <!-- <n-config-provider> -->
  <n-loading-bar-provider>
    <n-message-provider>
      <n-dialog-provider>
        <!-- <RouterView></RouterView> -->
        <!-- <RouterView v-if="userStore.stateData.isLoggedIn && dbStore.stateData.completed"></RouterView> -->
        <!-- <RouterView v-if="userStore.stateData.ready"></RouterView> -->
        <!-- <RouterView v-if="dbStore.stateData.completed"></RouterView> -->
        <RouterView
          v-if="$route.meta.auth_no_preference || (($route.meta.requires_auth || false) && dbStore.stateData.completed) || (!($route.meta.requires_auth || false) && !dbStore.stateData.completed)">
        </RouterView>
        <Loader v-else></Loader>
        <!-- {{ $route.meta.requires_auth && dbStore.stateData.completed }} -->
        <!-- <p>{{$route}}</p> -->
      </n-dialog-provider>
    </n-message-provider>
  </n-loading-bar-provider>
  <!-- </n-config-provider> -->
</template>

<style scoped>
</style>
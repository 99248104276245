import { defineStore } from 'pinia';
import { reactive, watch } from 'vue';

const storeName = 'user'

export default defineStore(storeName, () => {
    const stateData = reactive({
        isLoggedIn: false,
        uid: "",
        email: "",
        displayName: "",
        claims: {},
        raw: {}
    })

    const stateInStorage = localStorage.getItem(storeName)
    if (stateInStorage) {
        // Reminder - Initailize Database
        const parsedStateInStorage = JSON.parse(stateInStorage)
        for (let key of Object.keys(parsedStateInStorage)) {
            stateData[key] = parsedStateInStorage[key]
        }
    }

    watch(() => stateData, (state) => {
        localStorage.setItem(storeName, JSON.stringify(state))
    }, { deep: true })

    const clear = () => {
        stateData.isLoggedIn = false
        stateData.uid = ""
        stateData.email = ""
        stateData.displayName = ""
        stateData.claims = {}
        stateData.raw = {}
    }

    return {
        stateData, clear
    }
})